import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";

// CSS
import "@/assets/fonts.css";
import "@/assets/style.css";

// SOCKET
import SocketIO from "socket.io-client";
const app = createApp(App);

if (window.localStorage.getItem("server")) {
  app.config.globalProperties.$socket = SocketIO(
    window.localStorage.getItem("server") + "/client",
    {
      reconnectionDelayMax: 1000,
      path: "/socket",
    }
  );
  store.state.setup = true;
} else {
  store.state.setup = false;
  app.config.globalProperties.$socket = false;
}

app.use(store).use(router).mount("#app");
