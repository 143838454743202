<template>
  <div class="page">
    <div v-if="!scanning" class="form">
      <div class="contact">
        <h2>Kontaktdaten</h2>
        <div v-if="loading">Loading...</div>
        <div v-else>
          <form>
            <div>
              <label>Vorname</label>
              <input name="vname" v-model="mailinfo.FirstName" />
            </div>

            <div>
              <label>Name</label>
              <input name="name" v-model="mailinfo.LastName" />
            </div>

            <div>
              <label>Firma</label>
              <input name="name" v-model="mailinfo.Company" />
            </div>

            <div>
              <label>E-Mail-Adresse</label>
              <input name="email" v-model="mailinfo.Email" />
            </div>

            <div class="buttons">
              <button type="button" @click="start">QR-Code scannen</button>
              <button
                type="button"
                @click="sendMail"
                :class="{ sending: sending }"
              >
                Mail versenden
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div v-else>
      <div id="reader" ref="reader"></div>
    </div>

    <footer>
      <div class="logo"><img src="@/assets/img/logo.png" /></div>
      <nav>
        <button @click="back">Abbrechen und zum Konfigurator</button>
      </nav>
    </footer>
  </div>
</template>

<script>
import { Html5Qrcode } from "html5-qrcode";
import axios from "axios";

// TODO LOADING STATE

export default {
  name: "CameraView",
  data() {
    return {
      loading: false,
      scanner: undefined,
      scanning: false,
      sending: false,
      mailinfo: {},
      config: { fps: 10, qrbox: { width: 250, height: 250 } },
    };
  },
  methods: {
    back() {
      this.$store.state.userdata = undefined;
      this.$router.push({ path: "/" });
    },
    onScanSuccess(decodedText, decodedResult) {
      console.log("SCANNER: QR CODE FOUND: ", decodedText, decodedResult);

      this.scanner
        .stop()
        .then(() => {
          console.log("SCANNER: STOPPED");
          this.scanning = false;
          this.loading = true;
          this.fetchData(decodedText);
        })
        .catch((err) => {
          console.log("SCANNER: STOP FAILED", err);
          // Stop failed, handle it.
        });
    },
    onScanFailure() {
      // handle scan failure, usually better to ignore and keep scanning.
      // for example:
      //   console.warn(`Code scan error = ${error}`);
    },
    fetchData(barcode) {
      if (this.$store.state.userdata?.scan2lead)
        this.$store.state.userdata.scan2lead = false;
      let url = window.localStorage.getItem("server") + "/visitor/" + barcode;
      axios
        .post(url)
        .then((res) => {
          this.$store.state.userdata.scan2lead = res.data;
          this.mailinfo.FirstName = res.data.FirstName;
          this.mailinfo.LastName = res.data.LastName;
          this.mailinfo.Company = res.data.Company;
          this.mailinfo.Email = res.data.Email;
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
    start() {
      this.scanning = true;
      this.$nextTick(() => {
        this.scanner = new Html5Qrcode("reader", /* verbose= */ false);
        this.scanner.start(
          { facingMode: "environment" },
          this.config,
          this.onScanSuccess
        );
      });
    },
    sendMail() {
      this.sending = true;
      let url = window.localStorage.getItem("server") + "/mail";
      this.$store.state.userdata.mailinfo = this.mailinfo;
      axios.post(url, this.$store.state.userdata).then((res) => {
        console.log(res);
        if (res.status == 200) {
          this.sending = false;
          alert("Mail versendet!");
        }
      });
    },
  },
  created() {
    this.$socket.emit("toggle", { loop: true });
  },
};
</script>

<style lang="css" scoped>
.preview {
  max-height: 400px;
}

.hidden {
  height: 0;
  opacity: 0;
}

.form {
  background: lightgray;
  display: grid;
  place-items: center;
}

.contact {
  background: white;
  padding: 24px;
  border-radius: 15px;
}

.contact h2 {
  margin-top: 0;
}

.contact label {
  display: block;
  font-size: 0.9rem;
  margin-bottom: 6px;
}

.contact input {
  border: 1px solid black;
  outline: none;
  display: block;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 12px;
  font-size: 1rem;
  padding: 6px;
  font-family: Montserrat;
}

.contact .buttons {
  display: grid;
  grid-gap: 6px;
  grid-template-columns: min-content min-content;
}
</style>
