<template>
  <div class="page">
    <Configurator />

    <footer>
      <div class="logo"><img src="@/assets/img/logo.png" /></div>
      <nav>
        <button @click="showLoop">Imagefilm anzeigen</button>
        <button @click="saveData">Roboterarm anzeigen</button>
      </nav>
    </footer>
  </div>
</template>

<script>
import Configurator from "@/components/Configurator.vue";
import axios from "axios";
export default {
  name: "HomeView",
  components: { Configurator },
  data() {
    return {
      server: window.localStorage.getItem("server"),
    };
  },
  methods: {
    saveData() {
      let action = "post";
      if (this.$store.state.userdata) action = "put";

      axios[action](this.server + "/api", this.$store.state.userdata)
        .then((res) => {
          this.$store.state.userdata = res.data;
          this.$socket.emit("toggle", { loop: false });
          this.$router.push({ path: "camera" });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    showLoop() {
      this.$socket.emit("toggle", { loop: true });
    },
  },
  created() {},
};
</script>

<style lang="css" scoped></style>
