<template>
  <form @submit.prevent="upload">
    <div class="page">
      <div class="preview">
        <input
          type="file"
          capture="camera"
          accept="image/*"
          ref="camera"
          class="hidden"
        />
        <!-- <button type="button" @click="$refs.camera.click()">Camera</button> -->
        <img ref="preview" class="previewimg" v-show="file" />
        <div v-if="!file">Kamera öffnen zum Foto machen</div>
      </div>

      <footer>
        <div class="logo"><img src="@/assets/img/logo.png" /></div>
        <nav>
          <button type="button" @click="$refs.camera.click()">
            Kamera öffnen
          </button>
          <button type="submit" @click="upload" :class="{ inactive: !file }">
            Kontaktdaten erfassen
          </button>
          <button type="button" @click="back">Zurück zum Konfigurator</button>
        </nav>
      </footer>
    </div>
  </form>
</template>

<script>
// TODO LOADING STATE
import axios from "axios";

export default {
  name: "CameraView",
  data() {
    return {
      file: undefined,
      data: {},
    };
  },
  methods: {
    back() {
      this.$socket.emit("toggle", { loop: true });
      this.$router.push({ path: "/" });
    },
    upload() {
      let url = window.localStorage.getItem("server") + "/upload";
      this.data = this.$store.state.userdata;

      // BUILD FORMDATA
      let formData = Object.entries(this.data).reduce(
        (d, e) => (d.append(...e), d),
        new FormData()
      );
      formData.append("file", this.file);

      // SENT DATA
      axios
        .post(url, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          if (res.status == 200) {
            this.$store.state.userdata = res.data;
            this.$router.push({ path: "/mailer" });
          }
        });
    },
  },
  mounted() {
    this.$refs.camera.addEventListener("change", (e) => {
      this.file = e.target.files[0];
      this.$refs.preview.src = URL.createObjectURL(e.target.files[0]);
    });
  },
};
</script>

<style lang="css" scoped>
.previewimg {
  position: relative;
  display: block;
  height: 500px;
  border: 10px solid white;
}

.hidden {
  position: absolute;
  height: 0;
  opacity: 0;
}

.preview {
  position: relative;
  display: grid;
  place-items: center;
  background: lightgray;
}
</style>
