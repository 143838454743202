<template>
  <div></div>
</template>

<script>
export default {
  name: "ConfigContainer",
};
</script>

<style lang="css" scoped>
div {
  background: lightblue;
}
</style>
