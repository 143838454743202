import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/Home.vue";
import CameraView from "../views/Camera.vue";
import MailerView from "../views/Mailer.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/camera",
    name: "camera",
    component: CameraView,
  },
  {
    path: "/mailer",
    name: "mailer",
    component: MailerView,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
