import { createStore } from "vuex";

export default createStore({
  state: {
    connected: undefined,
    setup: false,
    userdata: undefined,
  },
  getters: {},
  mutations: {},
  actions: {},
  modules: {},
});
