<template>
  <div
    class="startup"
    ref="startup"
    :class="{ showStartup: !$store.state.setup }"
  >
    <!-- :class="{ showStartup: !$store.state.setup }" -->

    <input v-model="server" size="30" /><button @click="connect">
      Connect
    </button>
  </div>

  <div
    class="error"
    ref="error"
    :class="{ showError: showError }"
    @click="$store.state.setup = false"
  ></div>
</template>

<script>
// import SocketIO from "socket.io-client";

export default {
  name: "ConnectorOverlay",
  data() {
    return {
      showError: false,
      server: "",
    };
  },
  methods: {
    connect() {
      window.localStorage.setItem("server", this.server);
      this.$store.state.setup = true;
      if (!this.$socket) this.initSockets();
    },
    initSockets() {
      this.$socket.on("connect", () => {
        console.log("SOCKET: Connected");
        this.showError = false;
      });
      this.$socket.on("reconnect", () => {
        console.log("SOCKET: Reconnected");
        this.showError = false;
      });
      this.$socket.on("disconnect", () => {
        console.log("SOCKET: disconnected");
        this.showError = true;
      });
      this.$socket.on("error", () => {
        console.log("SOCKET: Error");
      });
      this.$socket.on("connect_error", () => {
        console.log("SOCKET: Connection error");
        this.showError = true;
      });
    },
  },
  created() {
    if (this.$socket) this.initSockets();
  },
};
</script>

<style lang="css" scoped>
.startup {
  background: #333;
  padding: 24px;
  color: white;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  transition: transform 1s;
  transform: translateY(-100%);
}

.error {
  position: fixed;
  left: 0;
  width: 100vw;
  transition: transform 1s;
  transform: translateY(100%);
  bottom: 0;
  display: block;
  background: red;
  color: white;
  height: 20px;
  z-index: 1000;
}

.showStartup {
  transform: translateY(0%);
}

.showError {
  transform: translateY(0);
}

input,
button {
  border: 0;
  outline: none;
  padding: 12px;
}
</style>
