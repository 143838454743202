<template>
  <!-- <nav>
  </nav> -->
  <router-view />
  <Connector />
</template>

<script>
import Connector from "@/components/Connector.vue";

export default {
  name: "HomeView",
  components: { Connector },
};
</script>
